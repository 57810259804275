<template>
  <div>
    <public-filter
      :action-methods="searchData"
      class="mb-2"
    />
    <b-col
      cols="12"
      class="mb-2 text-right"
    >
      <b-button
        variant="primary"
        to="/tirehotel/search"
      >
        <FeatherIcon icon="PlusIcon" /> Lastik Ekle
      </b-button>
    </b-col>
    <b-table
      striped
      hover
      responsive
      :fields="fields"
      :items="dataList"
    >
      <template #cell(customer)="data">
        <div>{{ data.item.customer }}</div>
        <span class="font-small-2 text-warning">{{ data.item.company }}</span>
      </template>
      <template #cell(brand)="data">
        {{ data.item.brand }} {{ data.item.model }}
      </template>
      <template #cell(tire_size)="data">
        <div>{{ data.item.tire_size }}</div>
        <span class="font-small-2 text-warning">
          {{ data.item.tire_brand }} - {{ data.item.tire_type }}
        </span>
      </template>
      <template #cell(control)="data">
        <b-button
          variant="primary"
          size="sm"
          :to="'/tirehotel/edit/' + data.item.id"
          class="mr-1"
        >
          Güncelle
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          :to="'/tirehotel/change/' + data.item.id"
        >
          Değiştir
        </b-button>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="dataCount"
      :per-page="perPage"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
    >
      <template #prev-text>
        <feather-icon
          icon="ChevronLeftIcon"
          size="18"
        />
      </template>
      <template #next-text>
        <feather-icon
          icon="ChevronRightIcon"
          size="18"
        />
      </template>
    </b-pagination>
  </div>
</template>

<script>
import {
  BCol, BTable, BButton, BPagination,
} from 'bootstrap-vue'
import PublicFilter from '@/views/Spareparts/TireHotel/Filter/PublicFilter.vue'

export default {
  name: 'Index',
  components: {
    BCol,
    BTable,
    BButton,
    BPagination,
    PublicFilter,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
        },
        {
          key: 'brand',
          label: 'Marka / Model',
        },
        {
          key: 'license_plate',
          label: 'Plaka',
        },
        {
          key: 'tire_size',
          label: 'Lastik',
        },
        {
          key: 'tire_shelf',
          label: 'Raf',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '260px', textAlign: 'left', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'com_tire_hotel.id AS id',
          'com_customer.name AS customer',
          'com_customer.company_name AS company',
          'com_swap_brand.name AS brand',
          'com_swap_model.title AS model',
          'com_cars.license_plate AS license_plate',
          'com_tire_size.title AS tire_size',
          'com_tire_brand.title AS tire_brand',
          'com_tire_type.title AS tire_type',
          'com_tire_shelf.title AS tire_shelf',
        ],
        limit: 10,
        start: 0,
        order_by: ['com_tire_hotel.id', 'DESC'],
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['tireHotel/dataList']
    },
    dataCount() {
      return this.$store.getters['tireHotel/dataCounts']
    },
    quickSearch() {
      return this.$store.getters['tireHotel/getQuickSearch']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('tireHotel/getDataList', this.dataQuery)
    },
    searchData() {
      if (this.quickSearch.keyword.length > 0) {
        this.dataQuery.or_like = {
          'com_customer.name': this.quickSearch.keyword,
          'com_customer.company_name': this.quickSearch.keyword,
        }
        this.getDataList()
      }
      if (this.quickSearch.license_plate.length > 0) {
        this.dataQuery.or_like = {
          'com_cars.license_plate': this.quickSearch.license_plate,
        }
        this.getDataList()
      }
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
  },
}
</script>
